<template>

<div class="f-upload">
    <b-card no-body>
      <b-spinner variant="primary" label="Loading..." v-if="loading"></b-spinner>
        <b-alert variant="success" v-if="feedback" show> {{feedback}}</b-alert>
      <b-tabs pills card lazy>
        <b-tab title="Upload Files" active>
        <form ref="fileform" @submit.prevent="upload">
        <div class="drag-area" ref="dragArea">

        <label for="file">
         <input
          class="box-file"
          type="file"
          name="file"
          id="file"
          multiple
          @change="addFiles($event.target.files)"
        />  <span class="box-dragndrop"> Drag Drop / </span>
          <strong>Click to Upload from Computer</strong>
        </label>

      </div>
      <div class="preview">
        <ul>
          <li v-for="(file, index) in files" :key="index">
            <img v-if="file.isImage" :src="file.src" :alt="file.name" />
            {{ file.name }}

            <button class="remove" @click.prevent="removeFile(index)">x</button>
          </li>
        </ul>
      </div>
        <b-alert variant="success" v-if="feedback" show> {{feedback}}</b-alert>
        <b-alert variant="danger" v-if="error" show> {{error}}</b-alert>
      <div class="form-action">
      <button class="btn btn-primary" :disabled="disableUpload || loading" type="submit">
         <b-spinner small v-if="loading"></b-spinner>
          Upload
        </button>
        <button class="btn btn-danger" v-if="files.length" @click.prevent="cancel">
          Clear
        </button> </div>
    </form>
         </b-tab>

        <b-tab title="Add Shortcut">
           <b-form inline>
     <b-input
      id="surl"
      v-model="shortcutUrl"
      class="mb-2 mr-sm-2 mb-sm-0"
      placeholder="Type or Paste your URL of the shortcut here"
    ></b-input>
    <b-button variant="outline-primary" @click.prevent="addShortcut">Add</b-button>
    <br/>
    <canvas id="pic"></canvas>
  </b-form>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>


</template>

<script>
import { isImage } from '@/util/util';

export default {
  name: 'FileUpload',
  props: ['folderId', 'folderName'],
  data() {
    return {
      dragDropEnabled: false,
      files: [],
      shortcutUrl: '',
      feedback: '',
      error: '',
      loading: false,
    };
  },
  computed: {
    disableUpload() {
      return this.files.length <= 0;
    },
  },
  methods: {
    /*
    Check if client browser supports drag and drop  */
    checkDragAndDrop() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window
      );
    },
    addFiles(files) {
      [...files].map((f) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        const item = f;
        const fileExists = this.checkForDuplicate(f);
        if (!fileExists) {
          if (isImage(item.type)) {
            item.isImage = true;
          }
          reader.onload = () => {
            item.src = reader.result;
            this.files.push(item);
          };
        }

        return item;
      });
    },
    checkForDuplicate(file) {
      if (file) {
        return this.files.some(
          x => x.name === file.name && x.size === file.size,
        );
      }
      return false;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    cancel() {
      this.files = [];
    },

    upload() {
      this.loading = true;
      const payload = { files: this.files, folderId: this.folderId };
      this.$store.dispatch('file/addFile', payload).then(() => {
        this.feedback = 'File(s) uploaded successfully.';
        this.loading = false;
        this.cancel();
        this.$emit('fileAdded');
      });
    },
    addShortcut() {
      if (this.shortcutUrl) {
        const payload = {
          shortcutUrl: this.shortcutUrl,
        };
        if (this.folderId) {
          payload.parentId = this.folderId;
        }
        this.loading = true;
        this.$store.dispatch('file/addShortcut', payload).then(() => {
          this.shortcutUrl = '';
          this.$emit('fileAdded');
          this.feedback = 'Shortcut added successfully.';
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    const { body } = document;
    body.setAttribute('ondragstart', 'return false');
    body.setAttribute('ondragover', 'return false');
    body.setAttribute('ondrop', 'return false');

    this.dragDropEnabled = this.checkDragAndDrop();
    if (this.dragDropEnabled) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach((evt) => {
        this.$refs.dragArea.addEventListener(
          evt,
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false,
        );
      });
      /**
       * List to drop event and add files
       */
      this.$refs.dragArea.addEventListener('drop', (e) => {
        this.addFiles(e.dataTransfer.files);
      });
    }
  },
  destroyed() {
    const { body } = document;
    body.removeAttribute('ondragstart');
    body.removeAttribute('ondragover');
    body.removeAttribute('ondrop');
    body.removeAttribute('onmousedown');
  },
};
</script>
<style scoped lang="stylus">
drag_area_border_color =#eee
drag_area_bg_color =#f7f7f7
file_upload_bg_color =#fff
file_upload_color =#000

.drag-area
  border: .1rem dashed drag_area_border_color;
  background-color: drag_area_bg_color;
  label
   padding: 9rem 17rem

.box-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.box-file + label * {
  pointer-events: none;
}
.remove {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 5rem;
  color: red;
}
ul {
  list-style: none;
  margin-top: 2rem;
}
li > img {
  border: .05rem solid orange;
  height:4rem
}
.card-header{
  background:none !important;
  border: 0;
}
.f-upload
  background file_upload_bg_color;
  color file_upload_color
  .card
    border:0;
  .form-action
    button
      margin-right:1rem
.form-inline
  .form-control
    width:80%
</style>
